<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <div class="input-content-wrap">
        <v-row class="pb-0">
          <v-col
            class="pb-0 pt-0"
            :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
            :cols="$store.getters.getMobileModel !== 'PDA' ? 7 : 8"
          >
            <v-text-field
              id="input-pcode"
              v-model="selectedPCode"
              autocomplete="off"
              label="품번"
              placeholder="QR 스캔"
              clearable
              @keyup.enter.prevent="onEnterPcode"
            />
          </v-col>
          <v-col
            v-if="$store.getters.getMobileModel !== 'PDA'"
            class="pb-0 pt-4 pl-0"
            cols="1"
          >
            <v-icon
              @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
            >
              mdi-camera
            </v-icon>
          </v-col>
          <v-col
            class="pb-0 pt-0"
            cols="4"
          >
            <v-text-field
              id="input-qty"
              v-model="qty"
              label="출하수량"
              placeholder="수량"
              autocomplete="off"
              type="number"
              clearable
              @keyup.enter.prevent="onEnterQty"
            />
          </v-col>
        </v-row>
        <v-row class="pb-0">
          <v-col
            class="pb-0 pt-0"
            cols="8"
          >
            <v-autocomplete
              id="input-company"
              v-model="selectedCompany"
              :items="companyInfoList"
              label="납품처"
              item-text="거래처명"
              item-value="거래처코드"
              auto-select-first
              @change="onCompanyChange"
              @keyup.enter.prevent="onCompanyChange"
            />
          </v-col>
          <!-- <v-col
            class="pb-0 pt-0"
            cols="4"
          >
            <v-text-field
              id="input-car-no"
              v-model="carNo"
              label="출하차량"
              placeholder="차량번호"
              :disabled="$store.getters.getCarNo !== null"
              clearable
              type="number"
              @keyup.enter.prevent="onEnterCarNo"
            />
          </v-col> -->
          <v-col
            cols="4"
          >
            <v-switch
              v-model="autoScan"
              :label="autoScan ? `자동출하` : `수동출하` "
              class="mt-0 pb-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn
              width="100%"
              color="primary"
              :disabled="autoScan ? forceDisable : autoScan"
              @click="onClickSave"
            >
              제품 출하 등록
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0 pb-0">
          <v-col
            cols="6"
          >
            <v-text-field
              :value="selectedGroup"
              label="차종"
              autocomplete="off"
              :readonly="true"
              filled
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              :value="qrKey"
              label="파레트번호"
              autocomplete="off"
              :readonly="true"
              filled
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            class="pt-0"
            cols="12"
          >
            <v-text-field
              :value="selectedGoodsName"
              label="품명"
              autocomplete="off"
              :readonly="true"
              filled
            />
          </v-col>
        </v-row>
      </div>
      <div
        class="data-grid"
        style="margin-top:6px; background-color:white;"
      >
        <dx-data-grid
          ref="refProductionReceiptGrid"
          :data-source="productionReceiptInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="발주번호"
          :focused-row-key.sync="focusedRowKey"
          @focused-row-changed="onSelectionChanged"
          @toolbar-preparing="onToolbarPreparing"
          @row-removed="onRowRemoved"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :fixed="false"
            :min-width="120"
          />
          <DxColumn
            :allow-editing="false"
            caption="수량"
            data-field="수량"
            format="#,##0"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="납품처"
            data-field="거래처명"
            sort-order="asc"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="출하차량"
            data-field="출하차량"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="품명"
            data-field="품명"
            :min-width="120"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
          <template #grid-title>
            <div
              class="toolbar-label"
              style="font-size:1.1rem;margin-left:0px;"
            >
              <b>{{ today }} {{ carNo }} 출하목록</b>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </v-container>
  </div>
</template>
<script>
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting, DxColumnFixing, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'ProductionReceipt',
  components: {
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxDataGrid,
    DxColumnFixing,
    DxSummary,
    DxTotalItem,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isSaving: false,
      userInfo: null,
      selectedPCode: null,
      qrKey: null,
      companyInfoList: [],
      selectedCompany: null,
      selectedGoodsName: null,
      selectedGroup: null,
      qty: null,
      carNo: null,
      productionReceiptInfo: null,
      gridHeightSub: null,
      focusedRowKey: null,
      today: AppLib.getToday(),
      isUpdateMode: false,
      selectedRow: null,
      isRegOutput: true, // 파레트 등록여부
      // qrcode scan
      showQrScaner: 'none',
      autoScan: true,
      forceDisable: true,
      orderDate: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.refreshCompany()
    this.refreshProductionReceipt()
  },
  mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    this.initInput()
    console.log(this.$store.getters.getCarNo)
    if (this.$store.getters.getCarNo !== null) {
      console.log('들어오니')
      this.carNo = this.$store.getters.getCarNo
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
      const drawContentHeight = this.$Q('.input-content-wrap').height()
      this.gridHeightSub = this.$Q('html').height() - drawContentHeight - 60
    },
    initInput () {
      setTimeout(() => {
        this.$Q('#input-pcode').focus()
      }, 100)
      if (this.isUpdateMode) {
        this.carNo = null
      }
      this.selectedCompany = null
      this.forceDisable = true
      this.selectedPCode = null
      this.qty = null
      this.qrKey = null
      this.isUpdateMode = false
      this.selectedGoodsName = null
      this.selectedGroup = null
    },
    getText (row) {
      return `${row.품번} (${row.품명})`
    },
    async onClickSave () {
      if (this.isSaving) return
      // if (this.carNo === null || this.carNo === undefined) {
      //   this.$snotify.error('출하차량 번호를 입력하여 주세요')
      //   this.$Q('#input-car-no').focus()
      //   return
      // }
      const pInfo = this.$store.getters.getGoodsItemByGoodsNo(this.selectedPCode)
      if (pInfo === undefined) {
        this.$snotify.info('해당 품목을 검색할 수 없습니다.')
        this.initInput()
        return
      }
      if (this.qty === undefined || this.qty === null || !AppLib.isNumeric(this.qty) || this.qty <= 0) {
        this.$snotify.error('출하수량을 확인하여 주세요.')
        this.$Q('#input-qty').focus()
        return
      }
      if (this.selectedPCode === null) {
        this.$snotify.error('제품 품번을 입력하여 주세요')
        this.$Q('#input-pcode').focus()
        return
      }
      if (this.selectedCompany === null) {
        this.$snotify.error('납품처를 선택하여 주세요')
        this.$Q('#input-company').click()
        return
      }
      let result = false
      let 단가 = 0
      await this.$_sp.runNoEncodeFindProc('spFindAllCostOrSaleByGoodsCodeAndSaleType', { 입출고구분: 2, 품번: this.selectedPCode, 기준일: AppLib.getNow() })
        .then((data) => {
          this.Loading = false
          const 가격정보 = this.$_sp.MakeModel(data)
          if (가격정보.length > 0) {
            단가 = 가격정보[0].가격
            result = true
          } else {
            result = false
          }
        })
        .catch(error => {
          this.Loading = false
          result = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (result === false) {
        this.$snotify.info('가격정보를 조회하지 못했습니다. 다시 확인하여주세요.')
        // return 2022.06.29 수정
      }
      this.isSaving = true
      let temp = null
      if (this.isUpdateMode === false) {
        temp = {
          발생일자: AppLib.getDeliveryTime(this.$store.getters.getSysParamByKey('재고관리기준시간')),
          입출고구분: this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품출하' }).코드,
          품번: this.selectedPCode,
          입고키: this.qrKey,
          수량: this.qty,
          단가: 단가,
          입고업체: this.selectedCompany,
          출하차량: this.carNo,
          발주번호: AppLib.uuidSequential(),
          createid: this.userInfo.userId,
          updateid: this.userInfo.userId,
          createtime: AppLib.getNow(),
          거래처명: this.$_.findWhere(this.companyInfoList, { 거래처코드: this.selectedCompany }).거래처명,
          품명: this.selectedRow.품명
        }
        if (this.isRegOutput === false && this.selectedRow.품목유형 === ConstDef.완제품) {
          const date = `${AppLib.getToday().substring(0, 7)}-01`
          const output = {
            생산기준일: `${AppLib.calcDateFromFormat(date, -1)}`,
            입고키: this.qrKey,
            품번: this.selectedPCode,
            양품: this.qty
          }
          this.$_sp.runUpdateProc('spSaveOutputNoneRegOutput', [output])
            .then((data) => {
            })
            .catch(error => {
              this.isSaving = false
              this.initInput()
              if (error.response.status === ConstDef.GENERRAL_ERROR) {
                this.$snotify.error(error.response.data.message)
              } else {
                this.$snotify.error(`에러코드 : ${error.response.status}`)
              }
            })
        }
        this.$_sp.runInsertSqlProc('창고수불', ['거래처명', '품명'], [temp])
          .then((data) => {
            this.isSaving = false
            this.$snotify.info('저장 되었습니다.', { timeout: 700 })
            this.focusedRowKey = temp.발주번호
            this.productionReceiptInfo.unshift(temp)
            this.initInput()
          })
          .catch(error => {
            this.isSaving = false
            this.initInput()
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      } else {
        console.log(this.selectedRow)
        if (this.selectedRow.발주번호 === null) {
          this.$snotify.error('발주번호가 없습니다.')
          return
        }
        temp = {
          발주번호: this.selectedRow.발주번호,
          품번: this.selectedRow.품번,
          입출고구분: this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품출하' }).코드,
          수량: this.qty,
          단가: 단가,
          입고업체: this.selectedCompany,
          출하차량: this.carNo,
          updateid: this.userInfo.userId,
          updatetime: AppLib.getNow()
        }
        this.$_sp.runUpdateSqlProc('창고수불', ['발주번호', '입출고구분', '품번'], null, [temp])
          .then((data) => {
            this.isSaving = false
            this.$snotify.info('저장 되었습니다.', { timeout: 700 })
            this.selectedRow.입고업체 = this.selectedCompany
            this.selectedRow.출하차량 = this.carNo
            this.selectedRow.수량 = this.qty
            this.selectedRow.거래처명 = this.$_.findWhere(this.companyInfoList, { 거래처코드: this.selectedCompany }).거래처명
            this.initInput()
          })
          .catch(error => {
            this.isSaving = false
            this.initInput()
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      }
    },
    onCompanyChange (e) {
      if (this.selectedCompany !== null) {
        setTimeout(() => {
          this.$Q('.v-autocomplete__content').css('display', 'none')
          this.$Q('#input-car-no').focus()
        }, 300)
      }
    },
    onEnterCarNo (e) {
      // insert 제품출고
      this.onClickSave()
    },
    onEnterQty (e) {
      if (this.qty !== null) {
        this.$Q('#input-company').click()
      }
    },
    async onEnterPcode (e) {
      if (this.selectedPCode === null || this.selectedPCode.length === 0) {
        return
      }
      let split = null
      split = AppLib.base64DecodeSubstring(this.selectedPCode).split('/')
      if (split.length === 1) {
        this.$snotify.error('일련번호를 확인할 수 없습니다.')
        return
      }
      this.qrKey = parseInt(split[1])
      this.selectedPCode = split[0].replace('?', ' ')

      const pInfo = this.$store.getters.getGoodsItemByGoodsNo(this.selectedPCode)
      if (pInfo === undefined) {
        this.$snotify.info('해당 품목을 검색할 수 없습니다.')
        this.initInput()
        return
      }
      await this.$_sp.runNoEncodeFindProc('spFindAllGoodsWorkoutByNoAndKey', { 품번: this.selectedPCode, 입고키: this.qrKey, 자재유형: pInfo.자재유형 })
        .then((data) => {
          const result = this.$_sp.MakeModel(data)
          if (result.length === 1) {
            this.isRegOutput = true
            this.selectedRow = result[0]
            this.showQrScaner = 'none'
            if (this.selectedRow.품목유형 === ConstDef.완제품 || this.selectedRow.자재유형 === ConstDef.외주직납품) {
              console.log(result)
              this.qty = this.selectedRow.파레트재고
              this.selectedCompany = this.selectedRow.납품처코드
            }
            if (this.qty === 0) {
              this.$snotify.error('해당 파레트는 이미 출하처리 되었거나 파레트상 재고가 없습니다.')
            }
            this.selectedGoodsName = this.selectedRow.품명
            this.selectedGroup = this.selectedRow.차종
            setTimeout(() => {
              if (this.autoScan) {
                this.onClickSave()
              } else {
              }
              this.$Q('#input-qty').focus()
            }, 10)
          } else {
            this.confirmRegOutput()
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    confirmRegOutput () {
      this.$snotify.confirm('해당 파레트 번호는 등록되어있지 않습니다. 실적등록 후 출하처리 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '출하',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.selectedRow = this.$store.getters.getGoodsItemByGoodsNo(this.selectedPCode)
              console.log(this.selectedRow)
              this.selectedGoodsName = this.selectedRow.품명
              this.selectedGroup = this.selectedRow.차종
              this.qty = 0
              this.selectedCompany = this.selectedRow.납품처코드
              this.isRegOutput = false
              this.forceDisable = false
              setTimeout(() => {
                this.$Q('#input-qty').focus()
              }, 10)
            }
          },
          {
            text: '취소',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.initInput()
            }
          }
        ]
      })
    },
    onPCodeScan (result) {
      this.selectedPCode = result
      this.onEnterPcode(null)
    },
    onRowDblClick (row) {
      this.isUpdateMode = true
      this.selectedPCode = row.data.품번
      this.selectedCompany = row.data.입고업체
      this.carNo = row.data.출하차량
      this.qty = row.data.수량
      this.selectedRow = row.data
      this.qrKey = row.data.입고키
      this.selectedGoodsName = row.data.품명
      this.selectedGroup = row.data.차종
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('창고수불', [{ 발주번호: row.data.발주번호 }])
        .then((data) => {
          this.$snotify.info('삭제 되었습니다.', { timeout: 700 })
          this.initInput()
        })
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onToolbarPreparing   (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'grid-title'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'edit',
            text: '수정',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRow === null) {
                this.$snotify.error('선택된 행이 없습니다')
                return
              }
              this.onRowDblClick({ data: this.selectedRow })
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'refresh',
            text: '수정취소',
            focusStateEnabled: true,
            onClick: (args) => {
              this.initInput()
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'trash',
            text: '삭제',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRow === null) {
                this.$snotify.error('선택된 행이 없습니다')
                return
              }
              const rowIndex = this.$refs.refProductionReceiptGrid.instance.getRowIndexByKey(this.focusedRowKey)
              this.$refs.refProductionReceiptGrid.instance.deleteRow(rowIndex)
            }
          }
        }
      )
    },
    refreshCompany () {
      this.$_sp.runNoEncodeFindSqlProc('거래처정보', { 거래처구분코드: '발주사' })
        .then((data) => {
          this.companyInfoList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshProductionReceipt () {
      const param = {
        발생일자: AppLib.getToday(),
        입출고구분: this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품출하' }).코드,
        createid: this.userInfo.userId
      }
      this.$_sp.runNoEncodeFindProc('spFindAllProductionReceiptByDateAndTypeAndCreateId', param)
        .then((data) => {
          this.productionReceiptInfo = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
    /* **************** 실적등록 **************** */
    // async addInventory () {
    //   this.getDateOrderDate()
    //   let 가격정보 = null
    //   await this.$_sp.runNoEncodeFindProc('spFindAllProductionSaleAndSubcontractPrice', { 품번: this.selectedPCode, 기준일: AppLib.getNow() })
    //     .then((data) => {
    //       가격정보 = this.$_sp.MakeModel(data)
    //       if (가격정보.length <= 0) {
    //         this.$snotify.info('판매가 또는 외주가공비가 조회되지않았습니다.')
    //       }
    //     })
    //     .catch(error => {
    //       if (error.response.status === ConstDef.GENERRAL_ERROR) {
    //         this.$snotify.error(error.response.data.message)
    //       } else {
    //         this.$snotify.error(`에러코드 : ${error.response.status}`)
    //       }
    //     })
    //   const param = {
    //     생산기준일: this.orderDate,
    //     입고키: this.qrKey,
    //     품번: this.selectedPCode,
    //     양품: parseInt(this.inputQty),
    //     불량: 0,
    //     재생산수량: 0,
    //     판매가: 가격정보[0].판매가,
    //     외주가공비: 가격정보[0].외주가공비,
    //     createid: this.userInfo.userId,
    //     updateid: this.userInfo.userId
    //   }
    //   this.$_sp.runInsertSqlProc('생산실적', null, [param])
    //     .then((data) => {
    //       this.isSaving = false
    //       this.focusedRowKey = this.qrKey
    //       this.goodsInputListInfo.push(param)
    //       this.initInput()
    //     })
    //     .catch(error => {
    //       this.isSaving = false
    //       this.initInput()
    //       if (error.response.status === ConstDef.GENERRAL_ERROR) {
    //         this.$snotify.error(error.response.data.message)
    //       } else {
    //         this.$snotify.error(`에러코드 : ${error.response.status}`)
    //       }
    //     })
    // },
    // getDateOrderDate () {
    //   const startTime = this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value
    //   const time = AppLib.getNowTime()
    //   if (time >= '00:00' && time < startTime) {
    //     this.orderDate = AppLib.calcDateFromToday(-1)
    //   } else {
    //     this.orderDate = AppLib.getToday()
    //   }
    // }
  }
}
</script>

<style lang="scss">

</style>
